import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Publications - Kimberly Plomp, PhD</title>
            <meta name="description" content="List of Publications" />
            <meta name="keywords" content="publications,journals,articles,book,chapters,abstracts" />
        </Helmet>

        <BannerLanding title="Publications" subtitle="Scientific journal articles and book chapters" />

        <div id="main">
            <section id="one" className="spotlights">
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Journal Articles</h3>
                            </header>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>2024</td>
                                            <td><b>Plomp KA,</b> Owen J, Dobney K, Collard M. Mandibular shape and diet in extant primates: a 3D geometric morphometric analysis. <i>Anthropologischer Anzeiger.</i> <a href="https://doi.org/10.1127/anthranz/2024/1675" target="_blank" rel="noreferrer">https://doi.org/10.1127/anthranz/2024/1675</a></td>
                                        </tr>
                                        <tr>
                                            <td>2023</td>
                                            <td>Timbrell L, Habte B, Tefera Y, Maroma C, Ndiema E, <b>Plomp KA,</b> Blinkhorn J, Grove M. Stone point variability reveals spatial, chronological and environmental structuring of eastern African Middle Stone Age populations. <i>Azania: Archaeological Research in Africa.</i> <a href="https://doi.org/10.1080/0067270X.2023.2268986" target="_blank" rel="noreferrer">https://doi.org/10.1080/0067270X.2023.2268986</a></td>
                                        </tr>
                                        <tr>
                                            <td>2023</td>
                                            <td><b>Plomp KA,</b> Dobney K, Gestsdóttir H, Collard M. Mixed ancestry of Europeans who settled Iceland and Greenland: 3D geometric-morphometric analyses of cranial base shape colonies in Greenland. <i>Antiquity.</i> 97(395):1249-1261. <a href="https://doi.org/10.15184/aqy.2023.131" target="_blank" rel="noreferrer">https://doi.org/10.15184/aqy.2023.131</a></td>
                                        </tr>
                                        <tr>
                                            <td>2022</td>
                                            <td>Collard M, Dobney K, <b>Plomp KA.</b> Can we combine 3D data obtained with a MicroScribe digitising arm and photogrammetry to address bioarchaeological research questions? <i>Journal of Archaeological Science: Reports</i> 46: 103676. <a href="https://doi.org/10.1016/j.jasrep.2022.103676" target="_blank" rel="noreferrer">https://doi.org/10.1016/j.jasrep.2022.103676</a></td>
                                        </tr>
                                        <tr>
                                            <td>2022</td>
                                            <td>Timbrell L, Scott C, Habte B, Tefera Y, Monod H, Qazzih M, Marais B, Black W, Maroma C, Ndiema E, Henderson S, Elmes K, <b>Plomp KA,</b> Grove M. Testing inter-observer error under a collaborative research framework for studying lithic shape variability. <i>Archaeological and Anthropological Sciences</i> 14(10): 1-15. <a href="https://doi.org/10.1007/s12520-022-01676-2" target="_blank" rel="noreferrer">https://doi.org/10.1007/s12520-022-01676-2</a></td>
                                        </tr>
                                        <tr>
                                            <td>2022</td>
                                            <td>Collard M, <b>Plomp KA,</b> Dobney K. Back pain in evolutionary perspective: updating a classic hypothesis. <i>Biological Theory.</i> <a href="https://doi.org/10.1007/s13752-022-00402-4" target="_blank" rel="noreferrer">https://doi.org/10.1007/s13752-022-00402-4</a></td>
                                        </tr>
                                        <tr>
                                            <td>2022</td>
                                            <td>Gilmour R, <b>Plomp KA.</b> The Changing Shape of Palaeopathology: The Contribution of Skeletal Shape Analyses to Investigations of Pathological Conditions. <i>Yearbook of Biological Anthropology.</i> <a href="https://doi.org/10.1002/ajpa.24475" target="_blank" rel="noreferrer">https://doi.org/10.1002/ajpa.24475</a></td>
                                        </tr>
                                        <tr>
                                            <td>2021</td>
                                            <td><b>Plomp KA,</b> Dobney K, Collard M. A 3D basicranial shape-based assessment of local and continental northwest European ancestry among 5<sup>th</sup> to 9<sup>th</sup> century CE Anglo-Saxons. <i>PLOS One.</i> <a href="https://doi.org/10.1371/journal.pone.0252477" target="_blank" rel="noreferrer">https://doi.org/10.1371/journal.pone.0252477</a></td>
                                        </tr>
                                        <tr>
                                            <td>2021</td>
                                            <td><b>Plomp KA,</b> Dobney K, Price N, Collard M. The composition of the founding population of Iceland: a basicranial perspective. <i>PLOS One</i> 16(2): e0246059. <a href="https://doi.org/10.1371/journal.pone.0246059" target="_blank" rel="noreferrer">https://doi.org/10.1371/journal.pone.0246059</a></td>
                                        </tr>
                                        <tr>
                                            <td>2020</td>
                                            <td><b>Plomp KA,</b> Dobney K, Collard M. Spondylolysis and spinal adaptations for bipedalism: The Overshoot Hypothesis. <i>Evolution, Medicine, and Public Health</i> 2020(1): 35-44. <a href="https://doi.org/10.1093/emph/eoaa003" target="_blank" rel="noreferrer">https://doi.org/10.1093/emph/eoaa003</a></td>
                                        </tr>
                                        <tr>
                                            <td>2019</td>
                                            <td><b>Plomp KA,</b> Strand Viðarsdóttir U, Weston D, Dobney K, Collard M. 3D shape analyses of extant primate and fossil hominin vertebrae support the Ancestral Shape Hypothesis for intervertebral disc herniation. <i>BMC Evolutionary Biology</i> 19: 226. <a href="https://doi.org/10.1186/s12862-019-1550-9" target="_blank" rel="noreferrer">https://doi.org/10.1186/s12862-019-1550-9</a></td>
                                        </tr>
                                        <tr>
                                            <td>2019</td>
                                            <td><b>Plomp KA,</b> Strand Viðarsdóttir U, Weston D, Dobney K, Collard M. Potential adaptations for bipedalism in the thoracic and lumbar vertebrae of H. sapiens: A 3D comparative analysis. <i>Journal of Human Evolution</i> 137: 102693. <a href="https://doi.org/10.1016/j.jhevol.2019.102693" target="_blank" rel="noreferrer">https://doi.org/10.1016/j.jhevol.2019.102693</a></td>
                                        </tr>
                                        <tr>
                                            <td>2019</td>
                                            <td>Timbrell L, <b>Plomp KA.</b> Using the shape of the basicranial portion of the temporal bone to distinguish between relatively closely-related human populations. <i>Journal of Archaeological Sciences Reports</i> 26: 101885. <a href="https://doi.org/10.1016/j.jasrep.2019.101885" target="_blank" rel="noreferrer">https://doi.org/10.1016/j.jasrep.2019.101885</a></td>
                                        </tr>
                                        <tr>
                                            <td>2016</td>
                                            <td><b>Plomp KA,</b> Boylston A. Frequency and patterns of costovertebral osteoarthritis in two Medieval English populations. <i>International Journal of Paleopathology</i> 14:64-68. <a href="https://doi.org/10.1016/j.ijpp.2016.05.007" target="_blank" rel="noreferrer">https://doi.org/10.1016/j.ijpp.2016.05.007</a></td>
                                        </tr>
                                        <tr>
                                            <td>2015</td>
                                            <td><b>Plomp KA,</b> Strand Viðarsdóttir U, Weston D, Dobney K, Collard M. The ancestral shape hypothesis: An evolutionary explanation for the occurrence of intervertebral disc herniation in humans. <i>BMC Evolutionary Biology</i> 15: 68. <a href="https://doi.org/10.1186/s12862-015-0336-y" target="_blank" rel="noreferrer">https://doi.org/10.1186/s12862-015-0336-y</a></td>
                                        </tr>
                                        <tr>
                                            <td>2015</td>
                                            <td><b>Plomp KA,</b> Roberts CA, Strand Viðarsdóttir U. Does the correlation between Schmorl’s nodes and vertebral morphology extend into the lumbar spine? <i>American Journal of Physical Anthropology</i> 157: 526-534. <a href="https://doi.org/10.1002/ajpa.22730" target="_blank" rel="noreferrer">https://doi.org/10.1002/ajpa.22730</a></td>
                                        </tr>
                                        <tr>
                                            <td>2015</td>
                                            <td><b>Plomp KA,</b> Roberts CA, Strand Viðarsdóttir U. Morphological characteristics of healthy and osteoarthritic joint surfaces in archaeological skeletons. <i>International Journal of Osteoarchaeology</i> 25: 515-527. <a href="https://doi.org/10.1002/oa.2319" target="_blank" rel="noreferrer">https://doi.org/10.1002/oa.2319</a></td>
                                        </tr>
                                        <tr>
                                            <td>2012</td>
                                            <td><b>Plomp KA,</b> Roberts CA, Strand Viðarsdóttir U. Vertebral morphology influences the development of Schmorl’s nodes in the lower thoracic spine. <i>American Journal of Physical Anthropology</i> 149(4): 572-582. <a href="https://doi.org/10.1002/ajpa.22168" target="_blank" rel="noreferrer">https://doi.org/10.1002/ajpa.22168</a></td>
                                        </tr>
                                        <tr>
                                            <td>2012</td>
                                            <td>Roberts CA, Bernofsky K, Cardoso FA, Henderson C, Jakob T, <b>Plomp KA,</b> Ponce P, Sharman J, Spencer R. Palaeopathology: studying the origin, evolution and frequency of disease in human remains from archaeological sites. <i>UNESCO Encyclopedia of Life Support Systems.</i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Edited Volumes</h3>
                            </header>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>2023</td>
                                            <td>Hirst C, Gilmour R, Alves Cardoso F, <b>Plomp KA</b> (eds.). <a href="https://www.elsevier.com/books/behaviour-in-our-bones/hirst/978-0-12-821383-4" target="_blank" rel="noreferrer"><i>Behaviour in our Bones.</i> Elsevier Press.</a></td>
                                        </tr>
                                        <tr>
                                            <td>2022</td>
                                            <td><b>Plomp KA,</b> Roberts CA, Bentley G, Elton S (eds.). <Link to="/evolvinghealth"><i>Palaeopathology and Evolutionary Medicine: An integrated approach.</i> Oxford University Press.</Link></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Book Chapters</h3>
                            </header>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>2023</td>
                                            <td><b>Plomp KA,</b> Gilmour R, Alves Cardoso F. Skeletons in Action: Inferring Behaviour from our Bones. In: Hirst C, Gilmour R, Alves Cardoso F, <b>Plomp KA</b> (eds.), <i>Behaviour in our Bones.</i> Elsevier Press.</td>
                                        </tr>
                                        <tr>
                                            <td>2023</td>
                                            <td><b>Plomp KA.</b> Behaviour and the bones of the thorax and spine. In: Hirst C, Gilmour R, Alves Cardoso F, <b>Plomp KA</b> (eds.), <i>Behaviour in our Bones.</i> Elsevier Press.</td>
                                        </tr>  
                                        <tr>
                                            <td>2023</td>
                                            <td>Gilmour R, <b>Plomp KA,</b> Alves Cardoso F. Acting On What We Have Learned and Moving Forward with Skeletal Behaviour. In: Hirst C, Gilmour R, Alves Cardoso F, <b>Plomp KA</b> (eds.), <i>Behaviour in our Bones.</i> Elsevier Press.</td>
                                        </tr>                           
                                        <tr>
                                            <td>2022</td>
                                            <td><b>Plomp KA,</b> Roberts CA, Bentley G, Elton S. What's it all about? A legacy for the next generation of scholars in evolutionary medicine and palaeopathology. In: <b>Plomp, KA,</b> Roberts, CA, Bentley G, Elton S (eds.), <i>Palaeopathology and Evolutionary Medicine; an integrated approach.</i> Oxford University Press.</td>
                                        </tr>
                                        <tr>
                                            <td>2022</td>
                                            <td><b>Plomp KA,</b> Been E, Collard M. Acquired spinal conditions in humans: the roles of spinal curvature, the shape of the lumbar vertebrae, and evolutionary history. In: <b>Plomp, KA,</b> Roberts, CA, Bentley G, Elton S (eds.), <i>Palaeopathology and Evolutionary Medicine; an integrated approach.</i> Oxford University Press.</td>
                                        </tr>
                                        <tr>
                                            <td>2022</td>
                                            <td>Bentley G, Roberts CA, Elton S, <b>Plomp KA.</b> Now you have read the book, what next? In: <b>Plomp, KA,</b> Roberts, CA, Bentley G, Elton S (eds.), <i>Palaeopathology and Evolutionary Medicine; an integrated approach.</i> Oxford University Press.</td>
                                        </tr>
                                        <tr>
                                            <td>2017</td>
                                            <td><b>Plomp KA.</b> The bioarchaeology of back pain. In: Brynes J, Muller J. <i>Bioarchaeology of Impairment and Disability: Theoretical, Ethnohistorical, and Methodological Perspectives.</i> Springer. Pp: 141-157.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Conference Abstracts</h3>
                            </header>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>2017</td>
                                            <td><b>Plomp KA,</b> Strand Viðarsdóttir U, Weston D, Dobney K, Collard M. Bipedalism evolved from knuckle-walking: Evidence from 3D geometric morphometric analyses of cervical and upper thoracic vertebral shape of <em>Homo sapiens. Pan troglodytes,</em> and <em>Pongo pygmaeus.</em> <i>American Journal of Physical Anthropology</i> 162: 148.</td>
                                        </tr>
                                        <tr>
                                            <td>2017</td>
                                            <td><b>Plomp KA,</b> Strand Viðarsdóttir U, Weston D, Dobney K, Collard M. Bipedalism evolved from knuckle-walking: Evidence from 3D geometric morphometric analyses of thoracic and lumbar vertebral shape of <em>Homo sapiens. Pan troglodytes,</em> and <em>Pongo pygmaeus.</em> <i>American Journal of Physical Anthropology</i> 162: 318.</td>
                                        </tr>
                                        <tr>
                                            <td>2015</td>
                                            <td><b>Plomp KA,</b> Strand Viðarsdóttir U, Weston D, Dobney K, Collard M. Ancestral Aches? 3D vertebral morphology, locomotion, and human spinal health. <i>American Journal of Physical Anthropology</i> 156: 254.</td>
                                        </tr>
                                        <tr>
                                            <td>2014</td>
                                            <td><b>Plomp KA,</b> Strand Viðarsdóttir U, Weston D, Dobney K, Collard M. Ancestral Aches? Vertebral morphology, locomotion, and human spinal health. <i>American Journal of Physical Anthropology</i> 153: 209.</td>
                                        </tr>
                                        <tr>
                                            <td>2013</td>
                                            <td><b>Plomp KA,</b> Strand Viðarsdóttir U, Roberts C. Applying anthropological shale analysis techniques to archaeological research: Overcoming problems and exploring possibilities. <i>American Journal of Physical Anthropology</i> 150: 277.</td>
                                        </tr>
                                        <tr>
                                            <td>2012</td>
                                            <td><b>Plomp KA,</b> Strand Viðarsdóttir U, Roberts C. The shape of the spinal canal and Schmorl’s nodes: a two-dimensional shape analysis of lower thoracic vertebrae. <i>American Journal of Physical Anthropology</i> 147: 238.</td>
                                        </tr>
                                        <tr>
                                            <td>2011</td>
                                            <td><b>Plomp KA,</b> Strand Viðarsdóttir U, Roberts C. Quantifying palaeopathology: Using geometric morphometrics to develop a quantifiable recording method for osteoarthritis. <i>American Journal of Physical Anthropology</i> 144: 240.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Landing
